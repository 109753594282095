import React from 'react';

import { Box } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
  boxClass?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, className, boxClass } = props;

  return (
    <div hidden={value !== index} className={className}>
      {value === index && <Box p={{ xs: 0, sm: 0, md: 0 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
