import React from 'react';

import Panel from 'src/components/panel/panel';
import * as styles from 'src/components/table-panel-title/table-panel-title.module.less';

interface TablePanelTitleProps {
  title: string;
}

const TablePanelTitle: React.FC<TablePanelTitleProps> = ({ title }) => {
  return (
    <Panel.Title>
      <span className={styles.capitalise}>{title}</span>
    </Panel.Title>
  );
};

export default TablePanelTitle;
