import React from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

interface TabsProps {
  value: number;
  labels: string[];
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabStyle?: string;
  tabsWrapperStyle?: string;
  indicatorStyle?: string;
  selectedStyle?: string;
}

const MUITabs: React.FC<TabsProps> = (props: TabsProps) => {
  const { labels, handleChange, value, tabStyle, tabsWrapperStyle, indicatorStyle, selectedStyle } = props;

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      classes={{ root: tabsWrapperStyle, indicator: indicatorStyle }}
    >
      {labels.map((label) => {
        return (
          <Tab key={label} label={label} className={tabStyle} classes={{ root: tabStyle, selected: selectedStyle }} />
        );
      })}
    </Tabs>
  );
};

export default MUITabs;
