import React from 'react';

import Chip from '@material-ui/core/Chip';
import * as styles from 'src/components/chip-squared/chip-squared.module.less';

interface ChipSquaredProps {
  label: string;
}

const ChipSquared: React.FC<ChipSquaredProps> = ({ label }) => {
  return <Chip className={styles.defaultChip} label={label} />;
};

export default ChipSquared;
